/* src/components/custom-bootstrap.css */

:root {
  --bs-primary: 0, 43, 73 !important; /* Navy in RGB */
  --bs-primary-rgb: 0, 43, 73 !important; /* Navy in RGB */
  --btn-primary: 0, 43, 73 !important; /* Navy in RGB */
  --bs-secondary: 108, 117, 125; /* #6c757d */
  --bs-success: 25, 135, 84; /* #198754 */
  --bs-info: 13, 202, 240; /* #0dcaf0 */
  --bs-warning: 255, 193, 7; /* #ffc107 */
  --bs-danger: 220, 53, 69; /* #dc3545 */
  --bs-light: 248, 249, 250; /* #f8f9fa */
  --bs-dark: 33, 37, 41; /* #212529 */
  /* Add any other Bootstrap variables you wish to customize */
}

/* Custom Orange Button Variant */
.btn-orange {
  background-color: #E57200;
  color: #ffffff;
  border-color: #E57200;
}

.btn-orange:hover {
  background-color: #cc5f00;
  border-color: #b35600;
}

/* Avoid overusing orange by limiting its use to specific components */

/* Make btn-primary use the custom --bs-primary color */
.btn-primary {
  background-color: rgb(var(--bs-primary));
  color: #ffffff; /* Text color */
  border-color: rgb(var(--bs-primary));
}

.btn-primary:hover {
  background-color: rgb(0, 35, 60); /* Slightly darker shade for hover */
  border-color: rgb(0, 35, 60);
}