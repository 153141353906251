/* src/features/ProductCatalogue/components/Filters.css */

.filters-card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .filters-card h5 {
    font-weight: 600;
  }
  
  .filters-card .form-check-label {
    cursor: pointer;
  }
  
  .filters-card .form-control {
    border-radius: 0.375rem;
  }
  
  .filters-card .card-body {
    padding: 0.75rem !important;
  }
  
  .accordion {
    --bs-accordion-btn-padding-x: 0.75rem;
    --bs-accordion-btn-padding-y: 0.5rem;
    --bs-accordion-body-padding-x: 0.5rem;
    --bs-accordion-body-padding-y: 0.5rem;
  }
  
  .accordion-button:not(.collapsed) {
    background-color: #f8f9fa;
    color: #212529;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  }
  
  .accordion-button {
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  }
  
  .accordion-body {
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  }
  
  .accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0,0,0,.125);
  }
  
  .accordion-item {
    border: 1px solid rgba(0,0,0,.125);
  }
  
  .filter-checkbox {
    margin-bottom: 0.25rem !important;
    padding-left: 1.5rem;
  }
  
  .filter-checkbox .form-check-input {
    margin-left: -1.5rem;
  }
  
  .filter-checkbox .form-check-label {
    cursor: pointer;
    font-size: 0.9rem;
    line-height: 1.2;
    display: flex;
    align-items: center;
    padding: 0.25rem 0;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .filter-checkbox .form-check-label span {
    flex: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .filter-checkbox .form-check-label svg {
    flex-shrink: 0;
  }
  
  .filter-checkbox:hover {
    background-color: #f8f9fa;
    border-radius: 4px;
  }
  
  .search-input {
    margin-bottom: 1rem !important;
  }
  
  .search-input .form-control {
    height: 38px;
    font-size: 0.9rem;
  }
  
  .brand-icon {
    flex-shrink: 0;
    object-fit: contain;
  }
  