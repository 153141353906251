/* src/components/Navbar.css */

/* Define a navy color variable for consistency */
:root {
    --navy-color: #002B49 !important; /* Navy in RGB */
    --navy-hover: #023163; /* Slightly lighter navy for hover effects */
    --orange-color: #ff7f50; /* Coral-like orange */
    --orange-hover: #ff5722; /* Darker orange on hover */
    --brand-color: #ffffff; /* White color for brand text */
    --link-color: #ffffff; /* White color for nav links */
    --link-hover-color: #ff7f50; /* Orange color on hover for nav links */
}

/* Apply the Inter font globally */
body {
    font-family: 'Inter', sans-serif;
}

/* General Navbar Styling */
.custom-navbar {
    background-color: var(--navy-color); /* Navy background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 0.75rem 1.5rem; /* Increased padding for a taller navbar */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.custom-navbar:hover {
    background-color: var(--navy-hover); /* Slight background change on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

/* Brand Logo Styling */
.navbar-logo {
    height: 50px; /* Increased logo height for better visibility */
    transition: transform 0.3s ease;
}

.navbar-logo:hover {
    transform: scale(1.05); /* Slight zoom on hover */
}

.brand-name {
    font-size: 1.5rem; /* Increased font size for better readability */
    font-weight: bold;
    color: var(--brand-color);
    transition: color 0.3s ease;
}

.brand-name:hover {
    color: var(--orange-color); /* Change color on hover */
}

/* Nav Link Styling */
.nav-link-custom {
    color: var(--link-color) !important; /* Ensure links are white */
    margin-right: 1rem; /* Spacing between links */
    transition: color 0.3s ease;
}

.nav-link-custom:hover {
    color: var(--link-hover-color) !important; /* Change to orange on hover */
    text-decoration: underline; /* Optional: underline on hover for better UX */
}

/* Button Styling */
.btn-orange {
    background-color: var(--orange-color); /* Coral-like orange */
    border: none;
    border-radius: 25px; /* Rounded edges for a friendly look */
    color: var(--brand-color);
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-orange:hover {
    background-color: var(--orange-hover); /* Darker orange on hover */
    transform: translateY(-2px); /* Slight lift effect on hover */
}

/* Icon Styling within Button */
.btn-orange .ms-2 {
    margin-left: 0.5rem;
}

/* Responsive Adjustments */
@media (max-width: 991.98px) {
    .brand-name {
        display: none; /* Hide brand name on smaller screens for simplicity */
    }

    .nav-link-custom {
        margin-right: 0.5rem; /* Reduce spacing on smaller screens */
    }

    .btn-orange {
        margin-top: 0.5rem; /* Add spacing on smaller screens */
        width: 100%; /* Make button full-width on smaller screens */
        justify-content: center; /* Center the button content */
    }
}
