/* src/components/NotFound.css */

.not-found-container {
    background-color: #f8f9fa; /* Light grey background */
    padding: 50px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .not-found-container h1 {
    color: #e57200; /* Brand color or any vibrant color */
  }
  
  .not-found-container p {
    color: #555555;
  }
  
  .fun-elements img {
    transition: transform 0.3s;
  }
  
  .fun-elements img:hover {
    transform: scale(1.1);
  }
  
  /* Animation for Thread Spool */
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .thread-spool {
    animation: rotate 20s linear infinite;
  }
  