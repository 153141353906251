/* src/components/ProductProofImagesComponent.css */

.proof-images-wrapper {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.proof-images-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 15px;
}

.proof-image {
    width: 10%;
    min-width: 80px; /* Ensures images don't become too small on very narrow screens */
    height: auto;
    object-fit: contain;
    border: 1px solid #ccc;
    border-radius: 4px;
}
